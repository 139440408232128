import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./auth/pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'register',
    loadComponent: () => import('./auth/pages/register/register.page').then( m => m.RegisterPage)
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./auth/pages/forgot-password/forgot-password.page').then( m => m.ForgotPasswordPage)
  },
  {
    path: 'home',
    loadComponent: () => import('./home/pages/home/home.page').then( m => m.HomePage)
  },
  {
    path: 'price-list',
    loadComponent: () => import('./home/pages/priceList/price-list/price-list.page').then( m => m.PriceListPage)
  },
  {
    path: 'price-list-cart',
    loadComponent: () => import('./home/pages/priceList/price-list-cart/price-list-cart.page').then( m => m.PriceListCartPage)
  },
  {
    path: 'forum-list',
    loadComponent: () => import('./home/pages/forum/forum-list/forum-list.page').then( m => m.ForumListPage)
  },
  {
    path: 'forum-single',
    loadComponent: () => import('./home/pages/forum/forum-single/forum-single.page').then( m => m.ForumSinglePage)
  },
  {
    path: 'forum-list/forum-subject-list/:id',
    loadComponent: () => import('./home/pages/forum/forum-subject-list/forum-subject-list.page').then( m => m.ForumSubjectListPage)
  },
  {
    path: 'forum-list/forum-create',
    loadComponent: () => import('./home/pages/forum/forum-create/forum-create.page').then( m => m.ForumCreatePage)
  },
  {
    path: 'logout',
    loadComponent: () => import('./home/pages/logout/logout.page').then( m => m.LogoutPage)
  },
  {
    path: 'change-profile-picture',
    loadComponent: () => import('./home/pages/change-profile-picture/change-profile-picture.page').then( m => m.ChangeProfilePicturePage)
  },
  {
    path: 'user-profile/:id',
    loadComponent: () => import('./home/pages/user-profile/user-profile.page').then( m => m.UserProfilePage)
  },
  {
    path: 'lists/:listType/:category/:title',
    loadComponent: () => import('./home/pages/lists/lists.page').then( m => m.ListsPage)
  },
  {
    path: 'nationality',
    loadComponent: () => import('./auth/pages/nationality/nationality.page').then( m => m.NationalityPage)
  },
  {
    path: 'report',
    loadComponent: () => import('./home/pages/report/report.page').then( m => m.ReportPage)
  },
  {
    path: 'cv-list',
    loadComponent: () => import('./home/pages/user-cv/cv-list/cv-list.page').then( m => m.CvListPage)
  },
  {
    path: 'cv-create',
    loadComponent: () => import('./home/pages/user-cv/cv-create/cv-create.page').then( m => m.CvCreatePage)
  },
  {
    path: 'cv-show/:ai',
    loadComponent: () => import('./home/pages/user-cv/cv-show/cv-show.page').then( m => m.CvShowPage)
  },
  {
    path: 'date-converter',
    loadComponent: () => import('./home/pages/date-converter/date-converter.page').then( m => m.DateConverterPage)
  },
  {
    path: 'ai-creator',
    loadComponent: () => import('./home/pages/user-cv/ai-creator/ai-creator.page').then( m => m.AiCreatorPage)
  },
];
