import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: any = null;
  private requestCount = 0;

  constructor(private loadingController: LoadingController) {}

  async present(message: string = 'Please wait...') {
    this.requestCount++;
    if (this.requestCount === 1) {
      this.loading = await this.loadingController.create({
        message,
        spinner: 'crescent',
      });
      await this.loading.present();
    }
  }

  async dismiss() {
    
    if (this.requestCount > 0) {
      this.requestCount--;
    }
    if (this.requestCount === 0 && this.loading) {
        await this.loading.dismiss();
        this.loading = null;

    }
  }
}
